
.header-desktop{
	display: block;
	padding-top: 20px;
	
	@media(max-width:1199px){
		display: none;
	}
}
.social-block ul{
	display: flex;
	align-items: center;
	li{width: 40px;}
	li a{
		 display: block;
		 
		}
	li +li {
		margin-left: 20px;
	}
	li:nth-child(2){width: 45px;}
}
.header-desktop .header-desktop__row{
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;

}
/* НАВИГАЦИЯ В ШАПКЕ*/
.header-desktop .header-desktop__nav{
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: 'Labrehab', Tahoma, sans-serif;
}
.header-desktop .header-desktop__nav>ul{
	display: flex;
	justify-content: space-between;
	&>li{
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;
		a{
			color: #000;
			text-transform: uppercase;
			font-family: 'Labrehab',  Tahoma, sans-serif;
		}
	}
	&>li:after{
		content: '';
		height: 1px;
		width: 0;
		background-color: #000;
		position: absolute;
		left: 0;
		bottom: 0;
		transition: .2s;
	}
	&>li.active:after{
		width: 100%;
	}
	&>li:before{
		content: '/';
		position: absolute;
		right: -20px;
		color: #000;
	}
	&>li:last-child:before{
		display: none;
	}
}
.header-desktop .header-desktop__nav>ul li>ul{
	position: absolute;
	left: -10px;
	bottom: -15px;
	transform: translateY(100%);
	padding: 10px;
	min-width: 200px;
	visibility: hidden;	
	opacity: 0;
	transition: .5s;
	background-color: #fafafa;
	
}
.header-desktop .header-desktop__nav>ul>li:hover:after{
	width: 100%;
}
.header-desktop .header-desktop__nav>ul li:hover ul{
	visibility: visible;
	opacity: 1;
}
.header-desktop .header-desktop__nav>ul li>ul li{
	border-bottom: 1px solid #000;
	padding-top: 10px;
	padding-bottom: 7px;
	&:hover{
		opacity: .5;
	}
}
/*BANNER*/

.header-banner__content{
	display: flex;
	justify-content: space-between;
	background-color: rgba(19, 37, 171, 1);
	padding: 30px;
}
.header-banner__img{
	width: 43%;
}
.header-banner__text{
	width: 50%;
	display: flex;
}
.header-banner__column{
	border: 2px solid #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	p{
		// text-align: center;
		text-transform: uppercase;
		color: #fff;
		font-family: 'Labrehab',  Tahoma, sans-serif;
		font-size: 16px;
		line-height: 1.5;
		white-space: nowrap;
	}
	.banner-box{
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 2px solid #fff;
		text-align: center;
		padding-left: 15px;
	padding-right: 15px;
	}
	.banner-btn{
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 20px;
	}
}
.banner-btn  a{
	display: inline-block;
	height: 45px;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 5px;
	line-height: 41px;
	text-transform: uppercase;
	font-family: 'Labrehab', Tahoma, sans-serif;
	font-size: 16px;
	padding-left: 16px;
	padding-right: 16px;
	white-space: nowrap;
	transition: .3s;
}
.banner-btn  a:hover{
	background-color: #fff;
	color:rgba(19, 37, 171, 1);
}
.header-banner__title{
	h1{
		font-family: 'Labrehab',  Tahoma, sans-serif;
		font-size: 28px;
		line-height: 1.3;
		text-transform: uppercase;
		margin-bottom: 15px;
		background-color: rgba(19, 37, 171, 1);
		position: relative;
		z-index: 2;
		padding-top: 5px;
		padding-bottom: 5px;
		transform:translate(-22%);
	}
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.header-banner__info{
	margin-left: 30px;
	span{
		display: block;
		width: 10px;
		height: 10px;
		position: relative;
		background-color: #fff;
		margin-bottom: 10px;
		&:after, &:before{
			content:'';
			width: 10px;
			height: 10px;
			background-color: #fff;
			opacity: .8;
			position: absolute;
			top: 0;
			right: -20px;
		}
		&:before{
			left: auto;
			right: -40px;
			opacity: .6;
		}
	}
	a{
		white-space: nowrap;
		color: #fff;
		padding-top: 5px;
		padding-bottom: 5px;
		display: inline-block;
		transition: .2s;
		&:hover{
			opacity: .5;
		}
	}
}