* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}
input[type="radio"],
input[type='checkbox'],
input[type="submit"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
a,
a:hover {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
}
input {
  outline: none;
}
.container {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.header-desktop {
  display: block;
  padding-top: 20px;
}
@media (max-width: 1199px) {
  .header-desktop {
    display: none;
  }
}
.social-block ul {
  display: flex;
  align-items: center;
}
.social-block ul li {
  width: 40px;
}
.social-block ul li a {
  display: block;
}
.social-block ul li + li {
  margin-left: 20px;
}
.social-block ul li:nth-child(2) {
  width: 45px;
}
.header-desktop .header-desktop__row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
/* НАВИГАЦИЯ В ШАПКЕ*/
.header-desktop .header-desktop__nav {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Labrehab', Tahoma, sans-serif;
}
.header-desktop .header-desktop__nav > ul {
  display: flex;
  justify-content: space-between;
}
.header-desktop .header-desktop__nav > ul > li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-desktop .header-desktop__nav > ul > li a {
  color: #000;
  text-transform: uppercase;
  font-family: 'Labrehab', Tahoma, sans-serif;
}
.header-desktop .header-desktop__nav > ul > li:after {
  content: '';
  height: 1px;
  width: 0;
  background-color: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: .2s;
}
.header-desktop .header-desktop__nav > ul > li.active:after {
  width: 100%;
}
.header-desktop .header-desktop__nav > ul > li:before {
  content: '/';
  position: absolute;
  right: -20px;
  color: #000;
}
.header-desktop .header-desktop__nav > ul > li:last-child:before {
  display: none;
}
.header-desktop .header-desktop__nav > ul li > ul {
  position: absolute;
  left: -10px;
  bottom: -15px;
  transform: translateY(100%);
  padding: 10px;
  min-width: 200px;
  visibility: hidden;
  opacity: 0;
  transition: .5s;
  background-color: #fafafa;
}
.header-desktop .header-desktop__nav > ul > li:hover:after {
  width: 100%;
}
.header-desktop .header-desktop__nav > ul li:hover ul {
  visibility: visible;
  opacity: 1;
}
.header-desktop .header-desktop__nav > ul li > ul li {
  border-bottom: 1px solid #000;
  padding-top: 10px;
  padding-bottom: 7px;
}
.header-desktop .header-desktop__nav > ul li > ul li:hover {
  opacity: .5;
}
/*BANNER*/
.header-banner__content {
  display: flex;
  justify-content: space-between;
  background-color: #1325ab;
  padding: 30px;
}
.header-banner__img {
  width: 43%;
}
.header-banner__text {
  width: 50%;
  display: flex;
}
.header-banner__column {
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-banner__column p {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Labrehab', Tahoma, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
}
.header-banner__column .banner-box {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #fff;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
.header-banner__column .banner-btn {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}
.banner-btn a {
  display: inline-block;
  height: 45px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  line-height: 41px;
  text-transform: uppercase;
  font-family: 'Labrehab', Tahoma, sans-serif;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
  transition: .3s;
}
.banner-btn a:hover {
  background-color: #fff;
  color: #1325ab;
}
.header-banner__title {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.header-banner__title h1 {
  font-family: 'Labrehab', Tahoma, sans-serif;
  font-size: 28px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 15px;
  background-color: #1325ab;
  position: relative;
  z-index: 2;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: translate(-22%);
}
.header-banner__info {
  margin-left: 30px;
}
.header-banner__info span {
  display: block;
  width: 10px;
  height: 10px;
  position: relative;
  background-color: #fff;
  margin-bottom: 10px;
}
.header-banner__info span:after,
.header-banner__info span:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #fff;
  opacity: .8;
  position: absolute;
  top: 0;
  right: -20px;
}
.header-banner__info span:before {
  left: auto;
  right: -40px;
  opacity: .6;
}
.header-banner__info a {
  white-space: nowrap;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  transition: .2s;
}
.header-banner__info a:hover {
  opacity: .5;
}
