// ::-webkit-scrollbar
*{box-sizing: border-box;}
html{scroll-behavior: smooth;}
html, body{
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

input[type="radio"], input[type='checkbox'], input[type="submit"]{
	appearance: none;
    -moz-appearance: none;
-webkit-appearance: none;
}
a,a:hover {text-decoration: none;}


ul{
	margin: 0;
	padding: 0;
	list-style-type: none;
}
h1, h2, h3, p {
	padding: 0;
	margin: 0;
}

img{max-width: 100%}
input{
	outline: none;
}



.container{
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
